import { lazy } from "react";

const PageGuide = lazy(() => import("../pages/PageGuide"));
const PageLogin = lazy(() => import("../pages/PageLogin"));
const PageNotFound = lazy(() => import("../../../page/PageNotFound"));
const PageSuperAdminWelcome = lazy(
  () => import("../../super_admin/pages/PageWelcome")
);
const PageSymetra = lazy(
  () => import("../../symetra/template/TemplateCalculator")
);
const PageSymetraPic = lazy(() => import("../../symetra/template/TemplatePic"));
const PageWelcomeAstor = lazy(() => import("../../astor/pages/PageWelcome"));
const PageWelcomeDefault = lazy(() => import("../pages/PageWelcome"));
const PageAura = lazy(() => import("../../halo/pages/PageAura"));
const PageAuraDemo = lazy(() => import("../../halo/pages/PageAuraDemo"));
const PageWelcomeLevel = lazy(() => import("../../level/pages/PageWelcome"));
const PageWelcomeMmi = lazy(() => import("../../mmi/pages/PageWelcome"));
const PageWelcomeOneascent = lazy(
  () => import("../../oneascent/page/PageWelcome")
);
const PagePublicQuizPerspectives = lazy(
  () => import("../../oneascent/page/PagePublicQuizPerspectives")
);
const PagePublicQuizPriorities = lazy(
  () => import("../../oneascent/page/PagePublicQuizPriorities")
);
const PagePublicQuizMilestones = lazy(
  () => import("../../oneascent/page/PagePublicQuizMilestones")
);

export default {
  "": PageWelcomeDefault,
  aura: PageAura,
  "aura-demo": PageAuraDemo,
  guide: PageGuide,
  login: PageLogin,
  "login-astor": PageWelcomeAstor,
  "login-mmi": PageWelcomeMmi,
  "login-level": PageWelcomeLevel,
  "questionnaire-milestones": PagePublicQuizMilestones,
  "questionnaire-perspectives": PagePublicQuizPerspectives,
  "questionnaire-priorities": PagePublicQuizPriorities,
  "login-oneascent": PageWelcomeOneascent,
  "login-super-admin": PageSuperAdminWelcome,
  symetra: PageSymetra,
  "symetra-pic": PageSymetraPic,
  "*": PageNotFound,
};
