import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import State from "./State";
import UI from "../../../../provider/UI";
import { notification } from "antd";
import Overlay from "../../../../atom/Overlay";

import defaultState from "./defaultState.json";
import mmiAdminTheme from "../../themes/admin";
import * as globalRequests from "../../requests/global";
import { setTenantSpecificHeadAttributes } from "../../../../utils/helper/specialized";

const StateProvider = ({ children }) => {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    setKeyValue("showOverlay", true);
    setTenantSpecificHeadAttributes("mmi");

    globalRequests
      .getUserEmailByJwt()
      .then(email => {
        globalRequests.getAdminDataByEmail(email).then(data => {
          if (data) {
            setKeyValue("user", data);
          } else {
            globalRequests.getUserDataByEmail(email).then(data => {
              setKeyValue("user", data);
            });
          }
        });
        setKeyValue("showOverlay", false);
      })
      .catch(() => {
        showError("Please log in.");
        setTimeout(() => navigate("/login-mmi"), 2000);
      });
  }, []);

  const reFetch = {
    companies: () =>
      globalRequests.getCompanies().then(data => {
        setKeyValue("companies", data);
        return data;
      }),
    events: () =>
      globalRequests.getEvents().then(data => {
        setKeyValue("events", data);
        return data;
      }),
    sponsors: () =>
      globalRequests.getSponsors().then(data => {
        setKeyValue("sponsors", data);
        return data;
      }),
    users: () =>
      globalRequests.getAdminDataByEmail(state.user?.email).then(data => {
        setKeyValue("user", data);
        return data;
      }),
  };

  const openModal = modalKey => {
    setState(state => ({
      ...state,
      [modalKey]: true,
    }));
  };

  const closeModal = modalKey => {
    setState(state => ({
      ...state,
      [modalKey]: false,
    }));
  };

  const showWarning = errorMessage => {
    api.warning({
      message: errorMessage,
      placement: "topRight",
      description: "",
    });
  };

  const showSuccess = successMessage => {
    api.success({
      message: successMessage,
      placement: "topRight",
      description: "",
    });
  };

  const showError = error => {
    console.log(error);

    api.error({
      message:
        typeof error === "string"
          ? error
          : (error.response?.data?.error ??
            error.response?.data?.message?.error ??
            error.response?.data ??
            error.message ??
            ""),
      placement: "topRight",
      description: "",
    });
  };

  const setKeyValue = (key, value) =>
    setState(lastState => ({
      ...lastState,
      [key]: value,
    }));

  const mergedState = {
    ...state,
    closeModal,
    openModal,
    reFetch,
    showWarning,
    showError,
    showSuccess,
    setKeyValue,
  };

  return (
    <State.Provider value={[mergedState, setState]}>
      <UI themeConfig={mmiAdminTheme}>
        <Overlay loading={state.showOverlay}>{children}</Overlay>
        {contextHolder}
      </UI>
    </State.Provider>
  );
};

export default StateProvider;
