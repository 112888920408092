import { lazy } from "react";

const PageAura = lazy(() => import("../pages/PageAura"));
const PageAuraDemo = lazy(() => import("../pages/PageAuraDemo"));
const PageAuraDemoInIframe = lazy(
  () => import("../pages/PageAuraDemoInIframe")
);
const PageNotFound = lazy(() => import("../../../page/PageNotFound"));

export default {
  "": PageAura,
  aura: PageAura,
  "aura-demo": PageAuraDemo,
  "aura-demo-in-iframe": PageAuraDemoInIframe,
  "*": PageNotFound,
};
